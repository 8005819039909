import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { Figure, Figcaption } from '../Caption'
import { IframeWrap, Iframe } from './styles'

export const CodePen = ({ slug, caption, user, height, theme, defaultTab }) => {
  if (!slug && !user) return null

  const href = `https://codepen.io/${user}/pen/${slug}`

  return (
    <>
      <Helmet>
        <script async src="https://static.codepen.io/assets/embed/ei.js" />
      </Helmet>
      <Figure>
        <p
          className="codepen"
          data-height={height}
          data-theme-id={theme}
          data-default-tab={defaultTab}
          data-user={user}
          data-slug-hash={slug}
          data-pen-title={slug}
          style={{
            alignItems: 'center',
            border: '2px solid',
            boxSizing: 'border-box',
            display: 'flex',
            height: height + 'px',
            justifyContent: 'center',
            marginBottom: caption ? '1px' : 0
          }}
        >
          <span>
            See a Demo <a href={href}>{slug}</a>
          </span>
        </p>
        {Boolean(caption) && <Figcaption>{caption}</Figcaption>}
      </Figure>
    </>
  )
}

CodePen.defaultProps = {
  user: 'hilja',
  height: 350,
  theme: 'light',
  defaultTab: 'result'
}

CodePen.propTypes = {
  slug: PropTypes.string,
  user: PropTypes.string,
  height: PropTypes.number,
  theme: PropTypes.oneOf(['light', 'dark']),
  defaultTab: PropTypes.oneOf(['result', 'css', 'js', 'html']),
  caption: PropTypes.string
}

export const YouTube = ({ slug, height, width, caption }) => {
  return (
    <Figure>
      <IframeWrap $hasCaption={Boolean(caption)}>
        <Iframe
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          frameBorder={0}
          height={height}
          load="lazy"
          src={`https://www.youtube-nocookie.com/embed/${slug}`}
          width={width}
        />
      </IframeWrap>
      {Boolean(caption) && <Figcaption>{caption}</Figcaption>}
    </Figure>
  )
}

YouTube.defaultProps = {
  height: 350,
  width: 560
}

YouTube.propTypes = {
  slug: PropTypes.string.isRequired,
  height: PropTypes.number,
  width: PropTypes.number,
  caption: PropTypes.string
}

export const CodeSandBox = props => {
  const { slug, height, hideNavigation, width, caption, theme } = props
  const src = `https://codesandbox.io/embed/${slug}?fontsize=14&hidenavigation=${hideNavigation}&theme=${theme}`

  return (
    <Figure>
      <IframeWrap>
        <Iframe
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          load="lazy"
          height={height}
          sandbox="allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
          src={src}
          title="copy-text-with-js"
          width={width}
        />
      </IframeWrap>
      {caption && <Figcaption>{caption}</Figcaption>}
    </Figure>
  )
}

CodeSandBox.defaultProps = {
  theme: 'light',
  hideNavigation: 1
}

CodeSandBox.propTypes = {
  caption: PropTypes.string,
  height: PropTypes.number,
  hideNavigation: PropTypes.number,
  slug: PropTypes.string.isRequired,
  theme: PropTypes.oneOf(['light', 'dark']),
  width: PropTypes.number
}

export const JsFiddle = props => {
  const { slug, height, title, width, caption, tabs, theme } = props
  const src = `https://jsfiddle.net/${slug}/embedded/${tabs}/${theme}`

  return (
    <Figure>
      <IframeWrap>
        <Iframe
          allowfullscreen="allowfullscreen"
          frameborder="0"
          height={height}
          load="lazy"
          src={src}
          title={title}
          width={width}
        />
      </IframeWrap>
      {caption && <Figcaption>{caption}</Figcaption>}
    </Figure>
  )
}

JsFiddle.defaultProps = {
  height: 300,
  tabs: 'js,html,css,result',
  theme: 'light',
  width: '100%'
}

JsFiddle.propTypes = {
  caption: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  slug: PropTypes.string.isRequired,
  title: PropTypes.string,
  theme: PropTypes.oneOf(['light', 'dark']),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tabs: PropTypes.string
}
