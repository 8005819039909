import React from 'react'
import PropTypes from 'prop-types'
import useDimensions from 'react-cool-dimensions'
import {
  AffBox,
  AffImageWrap,
  AffLink,
  AffWrap,
  DOLogo,
  Li,
  NCLogo,
  Sb,
  Ul,
  Wrap
} from './styles'
import useWindowSize from '../../hooks/useWindowSize'

const Affiliates = () => (
  <AffWrap>
    <AffBox>
      DigitalOcean server:
      <AffLink href="https://www.digitalocean.com/?refcode=5101def33865&utm_campaign=Referral_Invite&utm_medium=Referral_Program&utm_source=badge">
        <AffImageWrap>
          <DOLogo />
        </AffImageWrap>
        Sign up and get $100 credit!
      </AffLink>
    </AffBox>
    <AffBox>
      Namecheap domains:
      <AffLink href="https://namecheap.pxf.io/c/1327415/459740/5618">
        <AffImageWrap>
          <NCLogo />
        </AffImageWrap>
        Free Whois protection for life!
      </AffLink>
    </AffBox>
  </AffWrap>
)

const TocItem = ({ tableOfContents }) => {
  const subItem = (tableOfContents.items || []).map(item => (
    <Ul key={item.url}>
      {/* This is a recursive component */}
      <TocItem tableOfContents={item} type="child" />
    </Ul>
  ))

  return (
    <Li key={tableOfContents?.title}>
      <a href={tableOfContents?.url}>{tableOfContents?.title}</a>
      {subItem}
    </Li>
  )
}

TocItem.propTypes = {
  tableOfContents: PropTypes.shape({
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    items: PropTypes.array
  })
}

const TableOfContents = ({ tableOfContents }) => (
  <>
    <p>Contents:</p>
    <Ul>
      {(tableOfContents.items || []).map((item, index) => (
        <TocItem key={index} tableOfContents={item} />
      ))}
    </Ul>
  </>
)

TableOfContents.propTypes = {
  tableOfContents: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired
      })
    )
  })
}

const Sidebar = ({ tableOfContents, template }) => {
  const { height: windowHeight } = useWindowSize()
  const { observe, height: sidebarHeight } = useDimensions()
  // Don’t stick the sidebar if it’s taller than the window
  const isSticky = sidebarHeight < windowHeight

  return (
    <Sb template={template}>
      <Wrap ref={observe} isSticky={isSticky}>
        {tableOfContents.items && (
          <TableOfContents tableOfContents={tableOfContents} />
        )}
        <p>My affiliate links:</p>
        <Affiliates />
      </Wrap>
    </Sb>
  )
}

Sidebar.propTypes = {
  tableOfContents: PropTypes.shape({
    items: PropTypes.array
  }),
  template: PropTypes.oneOf(['wide', 'base'])
}

export default Sidebar
