import React from 'react'
import PropTypes from 'prop-types'

const AutoPlayingVideo = props => {
  const {
    loop,
    className,
    muted,
    autoPlay,
    playsInline,
    preload,
    controls,
    src
  } = props
  const videoRef = React.useRef(null)

  React.useEffect(() => {
    videoRef.current.defaultMuted = true
  })

  return (
    <video
      autoPlay={autoPlay}
      className={className}
      controls={controls}
      loop={loop}
      muted={muted}
      playsInline={playsInline}
      preload={preload}
      ref={videoRef}
    >
      <source src={src} type="video/mp4" />
    </video>
  )
}

AutoPlayingVideo.defaultProps = {
  autoPlay: true,
  controls: false,
  loop: true,
  muted: true,
  playsInline: true,
  preload: 'metadata'
}

AutoPlayingVideo.propTypes = {
  autoPlay: PropTypes.bool,
  className: PropTypes.string,
  controls: PropTypes.bool,
  loop: PropTypes.bool,
  muted: PropTypes.bool,
  playsInline: PropTypes.bool,
  preload: PropTypes.oneOf(['none', 'auto', 'metadata']),
  src: PropTypes.string.isRequired
}

export default AutoPlayingVideo
