import React from 'react'
import PropTypes from 'prop-types'
import { Figure, Figcaption } from '../Caption'
import { Vid } from './styles'

export const Gif = ({ src, caption }) => (
  <Figure>
    <Vid $hasCaption={Boolean(caption)} src={src} />
    {caption && <Figcaption>{caption}</Figcaption>}
  </Figure>
)

Gif.propTypes = {
  caption: PropTypes.string,
  src: PropTypes.string.isRequired
}

export const Video = props => {
  const {
    autoplay,
    caption,
    controls,
    loop,
    muted,
    playsInline,
    preload,
    src
  } = props

  return (
    <Figure>
      <Vid
        $hasCaption={Boolean(caption)}
        autoPlay={autoplay}
        controls={controls}
        loop={loop}
        muted={muted}
        playsInline={playsInline}
        preload={preload}
        src={src}
      />
      {caption && <Figcaption>{caption}</Figcaption>}
    </Figure>
  )
}

Video.defaultProps = {
  autoplay: false,
  controls: true,
  loop: false,
  muted: false,
  playsInline: true,
  preload: 'metadata'
}

Video.propTypes = {
  autoplay: PropTypes.bool,
  caption: PropTypes.string,
  controls: PropTypes.bool,
  loop: PropTypes.bool,
  muted: PropTypes.bool,
  src: PropTypes.string.isRequired,
  playsInline: PropTypes.bool,
  preload: PropTypes.oneOf(['none', 'auto', 'metadata'])
}
