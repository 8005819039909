import React from 'react'
import PropTypes from 'prop-types'
import Icon from '../Icon'
import { Wrap, IconContainer, Message, Link } from './styles'

const icons = {
  readMore: 'book',
  info: 'info',
  default: null
}

const messages = {
  readMore: 'Read more:'
}

const colors = {
  readMore: '--yellow',
  info: '--pink'
}

const Note = props => {
  const { children, type, link, text } = props
  const message = props.message || messages[type]
  const icon = icons[type]
  const color = colors[type] || '--gray'
  const isReadMore = type === 'readMore'

  return (
    <Wrap $color={color}>
      {icon && (
        <IconContainer>
          <Icon isBlock icon={icon} size="small" />
        </IconContainer>
      )}
      {message && <Message>{message}</Message>}
      {isReadMore ? link && <Link to={link}>{text}</Link> : text || children}
    </Wrap>
  )
}

Note.defaultProps = {
  type: 'default'
}

Note.propTypes = {
  children: PropTypes.node,
  link: PropTypes.string,
  message: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.string
}

export default Note
