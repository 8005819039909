import React from 'react'
import { MDXProvider } from '@mdx-js/react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import Gist from '@components/Gist'
import Post from '@components/Post'
import Base from '@components/Base'
import Meta from '@components/Meta'
import { Anchor } from '@components/Anchor'
import Comments from '@components/Comments'
import Sidebar from '@components/Sidebar'
import { CodePen, CodeSandBox, JsFiddle, YouTube } from '@components/Embeds'
import Image from '@components/Image'
import { Video, Gif } from '@components/Video'
import Iframe from '@components/Iframe'
import { H2, H3, H4, H5 } from '@components/Headings'
import { Details, Summary } from '@components/DetailSummary'
import Note from '@components/Note'
import { PostWrapper } from './styles'

// These are the components exposed to the MDX blog posts.
const shortCodes = {
  CodePen,
  CodeSandBox,
  Details,
  Gif,
  Gist,
  Iframe,
  Image,
  JsFiddle,
  Link,
  Note,
  Summary,
  Video,
  YouTube
}

// Configure custom styles for components here.
const components = {
  ...shortCodes,
  h2: props => <H2 {...props} isArticleTitle />,
  h3: props => <H3 {...props} isArticleTitle />,
  h4: props => <H4 {...props} isArticleTitle />,
  h5: props => <H5 {...props} isArticleTitle />,
  a: Anchor
}

const BlogPost = props => {
  const post = props.data.mdx
  const { categories, tags, title, template } = post.frontmatter || {}

  return (
    <Base size={template === 'wide' ? 'wide' : 'base'}>
      <Meta
        description={post.excerpt}
        location={props.location}
        pageTitle={title}
      />
      <PostWrapper>
        <MDXProvider components={components}>
          <Post
            body={post.body}
            categories={categories}
            tags={tags}
            title={title}
          />
        </MDXProvider>
        <Sidebar tableOfContents={post.tableOfContents} template={template} />
      </PostWrapper>
      <Comments />
    </Base>
  )
}

BlogPost.propTypes = {
  location: PropTypes.object,
  data: PropTypes.shape({
    mdx: PropTypes.shape({
      body: PropTypes.string,
      excerpt: PropTypes.string,
      tableOfContents: PropTypes.object,
      frontmatter: PropTypes.shape({
        categories: PropTypes.array,
        tags: PropTypes.array,
        template: PropTypes.oneOf(['wide', 'base']),
        title: PropTypes.string
      }),
      fields: PropTypes.object,
      title: PropTypes.string
    })
  })
}

export default BlogPost

export const query = graphql`
  query BlogPostQuery($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      tableOfContents
      excerpt
      fields {
        slug
      }
      frontmatter {
        title
        date
        categories
        tags
        template
      }
    }
  }
`
