import React from 'react'
import PropTypes from 'prop-types'
import { Figcaption } from '../Caption'
import { Iframe, Figure } from './styles'

const IframeComponent = props => {
  const { caption, width } = props
  const hasCaption = Boolean(caption)

  return (
    <Figure width={width}>
      <Iframe {...props} frameBorder={0} hasCaption={hasCaption} />
      {hasCaption && <Figcaption>{caption}</Figcaption>}
    </Figure>
  )
}

const srcPropsCheck = (props, propName, componentName) => {
  if (!props.src && !props.srcDoc) {
    return new Error(
      `One of 'src' or 'srcData' is required by '${componentName}' component.`
    )
  }
}

IframeComponent.defaultProps = {
  border: true,
  loading: 'lazy',
  height: 500
}

IframeComponent.propTypes = {
  border: PropTypes.bool,
  caption: PropTypes.string,
  height: PropTypes.number,
  loading: PropTypes.oneOf(['lazy', 'eager']),
  sandbox: PropTypes.string,
  src: srcPropsCheck,
  srcDoc: srcPropsCheck,
  title: PropTypes.string.isRequired,
  width: PropTypes.number
}

export default IframeComponent
