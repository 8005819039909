import React from 'react'
import { Commies } from './styles'

const Comments = () => {
  return (
    <Commies>
      Comments would go here, but the commenting system isn’t ready yet, sorry.
    </Commies>
  )
}

export default Comments
