import styles from 'styled-components'

// 1. On articles we want a nice top padding, unlike elsewhere.
// 2. Position the automatically inserted anchor.
const sharedStyles = props => ({
  paddingTop: props.isArticleTitle ? '13px' : undefined /* 1 */,
  position: 'relative',
  transitionDuration: '100ms',
  transitionProperty: 'transform fill',

  ':hover': {
    transform: 'translateX(5px)',

    '.anchor': {
      opacity: 1
    }
  },

  '.anchor': {
    left: '-2px' /* 2 */,
    top: '16px' /* 2 */,
    opacity: 0,
    padding: '0 2px',
    position: 'absolute',

    ':hover': {
      fill: 'var(--gray-dark)'
    }
  }
})

export const H2 = styles.h2(sharedStyles)
export const H3 = styles.h3(sharedStyles)
export const H4 = styles.h4(sharedStyles)
export const H5 = styles.h5(sharedStyles)
export const H6 = styles.h6(sharedStyles)
