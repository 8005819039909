import styled from 'styled-components'
import { SMALL_SCREEN } from '../../utils/variables'

export const PostTitle = styled.h2({
  marginBottom: 'var(--spacing-s)'
})

// 1. This is needed for the container to shrink all the way
export const PostWrap = styled.div({
  flex: '1',
  minWidth: 0 /* 1 */,
  marginRight: 0,
  paddingBottom: '20px',

  [SMALL_SCREEN]: {
    marginRight: '20px'
  }
})

export const Metadata = styled.div({
  fontSize: '90%',
  fontVariantNumeric: 'tabular-nums',
  marginBottom: '20px'
})
