import styled from 'styled-components'
import { Link as GatsbyLink } from 'gatsby'

export const Wrap = styled.div(props => ({
  alignItems: 'center',
  backgroundColor: `var(${props.$color})`,
  borderRadius: 'var(--border-radius)',
  display: 'flex',
  marginBottom: '20px',
  lineHeight: '1.5',
  padding: 'calc(0.8125rem / 2) calc(0.8125rem / 1.5)',

  p: {
    letterSpacing: 'initial'
  },

  'p:last-child': {
    marginBottom: 0
  }
}))

export const IconContainer = styled.span({
  marginRight: '10px'
})

export const Message = styled.span({
  fontWeight: 'bold',
  marginRight: '5px'
})

export const Link = styled(GatsbyLink)({
  color: 'var(--black)'
})
