import React from 'react'
import PropTypes from 'prop-types'
import kebabCase from 'lodash/kebabCase'
import { Link } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import useMetadata from '../../hooks/useMetadata'
import { PostWrap, PostTitle, Metadata } from './styles'

const separator = (tags, index) => (index + 1 === tags.length ? '' : ', ')

const Meta = props => (
  <>
    <strong>{props.title}</strong>
    {props.items.map((item, index) => (
      <React.Fragment key={item}>
        <Link key={item} to={`/${props.path}/${kebabCase(item)}`}>
          {item}
        </Link>
        {separator(props.items, index)}
      </React.Fragment>
    ))}
  </>
)

Meta.propTypes = {
  items: PropTypes.array.isRequired,
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
}

const Post = ({ title, categories, tags, body }) => {
  const { siteUrl } = useMetadata()

  return (
    <PostWrap>
      <PostTitle>{title}</PostTitle>
      <Metadata>
        {(categories || []).length > 0 && (
          <Meta items={categories} title="Filed under: " path="category" />
        )}
        {(tags || []).length > 0 && (
          <Meta items={tags} title="— Tagged with: " path="tag" />
        )}
      </Metadata>
      <MDXRenderer siteUrl={siteUrl}>{body}</MDXRenderer>
    </PostWrap>
  )
}

Post.defaultProps = {
  categories: [],
  tags: []
}

Post.propTypes = {
  body: PropTypes.string.isRequired,
  categories: PropTypes.array,
  tags: PropTypes.array,
  title: PropTypes.string.isRequired
}

export default Post
