import styled from 'styled-components'

export const CodePenWrap = styled.p({
  alignItems: 'center',
  border: '2px solid',
  boxSizing: 'border-box',
  display: 'flex',
  height: '265px',
  justifyContent: 'center',
  margin: '20px 0',
  padding: '20px'
})

// 1. 16:9 aspect ratio.
export const IframeWrap = styled.div(props => ({
  position: 'relative',
  paddingBottom: '56.25%' /* 1 */,
  height: 0,
  marginBottom: props.$hasCaption ? 0 : '3px'
}))

export const Iframe = styled.iframe({
  border: '1px solid var(--gray)',
  height: '100%',
  left: 0,
  position: 'absolute',
  top: 0,
  width: '100%'
})
