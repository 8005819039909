import styled from 'styled-components'

export const Summary = styled.summary({
  cursor: 'pointer',
  transitionProperty: 'padding',
  transitionDuration: '200ms',

  ':focus': {
    outline: '1px dotted var(--gray-dark)'
  },
  ':focus:not(:focus-visible)': {
    outline: 'none'
  },
  ':focus-visible': {
    outline: '1px dotted var(--gray-dark)'
  }
})

// 1. A visually pleasing value.
export const Details = styled.details({
  borderRadius: 'var(--border-radius)',
  border: '1px dashed var(--gray-dark)',
  display: 'block',
  margin: '20px 0',
  padding: '10px 15px',

  '&[open] summary': {
    padding: '0 0 12px 5px' /* 1 */
  }
})
