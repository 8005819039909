import styled from 'styled-components'
import { SMALL_SCREEN, LARGE_SCREEN } from '../../utils/variables'
import Do from '../../icons/Do'
import Nc from '../../icons/Nc'

const AFFILIATE_SIZE = '93px'

export const Sb = styled.aside(({ template }) => ({
  color: 'var(--gray-dark)',
  flex: '0 0 10em',
  fontSize: 'var(--font-small)',
  lineHeight: '1.3',
  margin: '0 auto 20px auto',
  paddingLeft: 0,

  [LARGE_SCREEN]: {
    flex: `0 0 ${template === 'wide' ? '10em' : '20em'}`
  }
}))

export const Wrap = styled.div(({ isSticky }) => ({
  position: isSticky ? 'sticky' : undefined,
  top: '20px'
}))

export const AffWrap = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '10px',
  textAlign: 'center'
})

export const AffBox = styled.div({
  gap: '10px',
  backgroundColor: 'var(--gray-lightest)',
  borderRadius: 'var(--border-radius)',
  flex: '1 1 calc(50% - 5px)',
  padding: '10px',
  position: 'relative',
  transition: 'transform 200ms',

  ':hover': {
    transform: 'scale(1.02)'
  }
})

export const AffLink = styled.a({
  color: 'var(--gray-dark)'
})

export const AffImageWrap = styled.div({
  display: 'flex',
  justifyContent: 'center'
})

const logoStyles = {
  height: AFFILIATE_SIZE,
  width: AFFILIATE_SIZE
}

export const DOLogo = styled(Do)(logoStyles)
export const NCLogo = styled(Nc)(logoStyles)

// 1. When the screen is narrow, try to optimize the horizontal space in the Toc
//    list.
export const Ul = styled.ul({
  margin: '0 20px 20px 20px',

  [SMALL_SCREEN]: {
    margin: '0 0 20px 5px' // 1
  },

  [LARGE_SCREEN]: {
    margin: '0 20px 20px 20px'
  }
})

export const Li = styled.li({
  ul: {
    marginBottom: 'calc(var(--spacing-m) / 2)'
  },

  a: {
    color: 'var(--black)',
    display: 'block',
    marginBottom: 'calc(var(--spacing-m) / 2)'
  }
})
