import styled from 'styled-components'
import { Figure as Fig } from '../Caption'

export const Figure = styled(Fig)(props => ({
  ...(props.width
    ? { width: props.width }
    : {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        width: '100%'
      })
}))

export const Iframe = styled.iframe(props => ({
  border: props.border ? '1px solid var(--gray)' : undefined,
  flexGrow: 1,
  marginBottom: props.hasCaption ? '3px' : '5px',
  padding: 0
}))
