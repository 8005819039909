import styled from 'styled-components'
import { SMALL_SCREEN } from '../../utils/variables'

export const PostWrapper = styled.div({
  display: 'block',
  borderBottom: '1px solid var(--gray)',
  marginBottom: '20px',

  [SMALL_SCREEN]: {
    display: 'flex',
    flex: 1
  }
})
